export const GeneralInstructionsSlug = "general-instructions";
export const DriverBriefingSlug = "driver-briefing";
export const WeighingSlug = "weighing";
export const SamplingSlug = "sampling";
export const SiteAddressSlug = "site-address";
export const SiteNavigationSlug = "site-navigation";
export const SiteOpeningHoursSlug = "site-opening-hours";
export const UlpOpeningHoursSlug = "ulp-opening-hours";
export const SiteAccessRestrictionsSlug = "site-access-restrictions";
export const UlpAccessRestrictionsSlug = "ulp-access-restrictions";
export const PpeRequirementsSlug = "ppe-requirements";
export const CheckInSlug = "check-in";
export const ParkingSlug = "parking";
export const WeighingBridgeSlug = "weighing-bridge";
export const InfrastructureSlug = "infrastructure";
export var SummaryType;
(function (SummaryType) {
    SummaryType["Loading"] = "loading";
    SummaryType["Unloading"] = "unloading";
})(SummaryType || (SummaryType = {}));
