import { NavigationSubmodule } from "@/app/types/store.type";
export const NavigationSubmoduleItems = [
    {
        translateKey: "modules.site-information.display",
        iconName: "holiday_village",
        navigationSubmodule: NavigationSubmodule.SiteInformation,
    },
    {
        translateKey: "modules.instructions.display",
        iconName: "edit_note",
        navigationSubmodule: NavigationSubmodule.Instructions,
    },
];
