import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = { class: "opening-hours" };
const _hoisted_2 = {
    key: 0,
    class: "column-description empty"
};
const _hoisted_3 = { key: 1 };
const _hoisted_4 = { class: "col-6 column-header" };
const _hoisted_5 = {
    key: 0,
    class: "col-6 column-description"
};
const _hoisted_6 = {
    key: 1,
    class: "col-6 column-description"
};
const _hoisted_7 = {
    key: 2,
    class: "col-6 column-description"
};
const _hoisted_8 = {
    key: 3,
    class: "col-6 column-description"
};
const _hoisted_9 = { key: 0 };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.hasData())
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t("global.phrases.empty-opening-hours")), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.weekdays, (day) => {
                    return (_openBlock(), _createElementBlock("div", {
                        key: day,
                        class: "row section"
                    }, [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t(`global.temporal.weekdays.${day}`)), 1),
                        (!_ctx.isOpen(day))
                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t("shared.components.opening-hours-widget.closed")), 1))
                            : (_ctx.isOpenAllDay(day))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t("shared.components.opening-hours-widget.open-all-day")), 1))
                                : (_ctx.hasNotDefinedHours(day))
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t("shared.components.opening-hours-widget.undefined")), 1))
                                    : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getOpeningHours(day), (hours, index) => {
                                            return (_openBlock(), _createElementBlock("div", { key: index }, [
                                                _createTextVNode(_toDisplayString(hours), 1),
                                                (index + 1 !== _ctx.getOpeningHours(day).length)
                                                    ? (_openBlock(), _createElementBlock("span", _hoisted_9, ","))
                                                    : _createCommentVNode("", true)
                                            ]));
                                        }), 128))
                                    ]))
                    ]));
                }), 128))
            ]))
    ]));
}
