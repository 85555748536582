export var NavigationMenu;
(function (NavigationMenu) {
    NavigationMenu["None"] = "None";
    NavigationMenu["Menu"] = "Menu";
    NavigationMenu["Language"] = "Language";
    NavigationMenu["Options"] = "Options";
})(NavigationMenu || (NavigationMenu = {}));
export var NavigationModule;
(function (NavigationModule) {
    NavigationModule["LoadingPoint"] = "LoadingPoint";
    NavigationModule["UnloadingPoint"] = "UnloadingPoint";
})(NavigationModule || (NavigationModule = {}));
export var NavigationSubmodule;
(function (NavigationSubmodule) {
    NavigationSubmodule["None"] = "None";
    NavigationSubmodule["Instructions"] = "Instructions";
    NavigationSubmodule["SiteInformation"] = "SiteInformation";
})(NavigationSubmodule || (NavigationSubmodule = {}));
export var SortOption;
(function (SortOption) {
    SortOption["MostRecent"] = "MostRecent";
    SortOption["Oldest"] = "Oldest";
})(SortOption || (SortOption = {}));
export const EnLocale = "en";
export const DeLocale = "de";
export const PlLocale = "pl";
export const EsLocale = "es";
export const ItLocale = "it";
export const FrLocale = "fr";
export const RoLocale = "ro";
