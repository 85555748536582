import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "image-slider" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Swiper = _resolveComponent("Swiper");
    const _component_Drawer = _resolveComponent("Drawer");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Swiper, {
            onClick: _ctx.toggle,
            images: _ctx.images
        }, null, 8, ["onClick", "images"]),
        _createVNode(_component_Drawer, {
            visible: _ctx.fullScreen,
            "onUpdate:visible": _cache[0] || (_cache[0] = ($event) => ((_ctx.fullScreen) = $event)),
            position: "full"
        }, {
            default: _withCtx(() => [
                _createVNode(_component_Swiper, { images: _ctx.images }, null, 8, ["images"])
            ]),
            _: 1
        }, 8, ["visible"])
    ]));
}
