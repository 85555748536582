import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "empty-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AccessRestriction = _resolveComponent("AccessRestriction");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createBlock(_component_SubModule, {
        title: _ctx.ulpAccessRestrictionTitle,
        icon: "local_shipping",
        hasData: _ctx.hasData,
        noDataMsg: _ctx.$t('loady2go.no-data'),
        name: _ctx.UlpAccessRestrictionsSlug
    }, {
        default: _withCtx(() => [
            (!_ctx.isUlpAccessRestrictionAvailable)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t("loady2go.no-data")), 1))
                : (_openBlock(), _createBlock(_component_AccessRestriction, {
                    key: 1,
                    accessRestriction: _ctx.modelValue
                }, null, 8, ["accessRestriction"]))
        ]),
        _: 1
    }, 8, ["title", "hasData", "noDataMsg", "name"]));
}
