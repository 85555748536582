import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = { class: "options-menu" };
const _hoisted_2 = ["onClick"];
const _hoisted_3 = { class: "item-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
            return (_openBlock(), _createElementBlock("div", {
                key: option.translationKey
            }, [
                _createElementVNode("div", {
                    class: "menu-item",
                    onClick: ($event) => (_ctx.setOption(option.value))
                }, [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t(`global.phrases.${option.translationKey}`)), 1),
                    _createElementVNode("div", {
                        class: _normalizeClass(["radio-button", { 'is-active': _ctx.isOptionActive(option.value) }])
                    }, [
                        (_ctx.isOptionActive(option.value))
                            ? (_openBlock(), _createBlock(_component_Icon, {
                                key: 0,
                                variant: "rounded",
                                class: "icon",
                                name: "check"
                            }))
                            : _createCommentVNode("", true)
                    ], 2)
                ], 8, _hoisted_2),
                _cache[0] || (_cache[0] = _createElementVNode("hr", null, null, -1))
            ]));
        }), 128))
    ]));
}
