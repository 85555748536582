import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Address = _resolveComponent("Address");
    const _component_MapsButton = _resolveComponent("MapsButton");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createBlock(_component_SubModule, {
        title: _ctx.$t('sub-modules.gates.address'),
        icon: "location_on",
        hasData: _ctx.hasData,
        noDataMsg: _ctx.$t('loady2go.no-data'),
        name: _ctx.SiteAddressSlug
    }, {
        default: _withCtx(() => [
            _createVNode(_component_Address, { address: _ctx.addressWithoutLocation }, null, 8, ["address"]),
            _createVNode(_component_MapsButton, { location: _ctx.location }, null, 8, ["location"])
        ]),
        _: 1
    }, 8, ["title", "hasData", "noDataMsg", "name"]));
}
