import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "submodules-navigation" };
const _hoisted_2 = { class: "card-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_NavigationItem = _resolveComponent("NavigationItem");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredNavigationSubmoduleItems, (item) => {
                return (_openBlock(), _createBlock(_component_NavigationItem, {
                    title: _ctx.$t(item.translateKey),
                    icon: item.iconName,
                    isSvg: item.isSvg,
                    navigationSubmodule: item.navigationSubmodule,
                    key: item.translateKey,
                    disabled: _ctx.isDisabled(item.navigationSubmodule)
                }, null, 8, ["title", "icon", "isSvg", "navigationSubmodule", "disabled"]));
            }), 128))
        ])
    ]));
}
