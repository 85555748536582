import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ParkingData = _resolveComponent("ParkingData");
    const _component_ParkingAccessRestrictions = _resolveComponent("ParkingAccessRestrictions");
    const _component_CollapsibleSubmoduleItem = _resolveComponent("CollapsibleSubmoduleItem");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createBlock(_component_SubModule, {
        title: _ctx.$t('loady2go.sub-modules.parking.name'),
        icon: "local_parking",
        hasData: _ctx.hasData,
        noDataMsg: _ctx.$t('sub-modules.parking-space.empty'),
        showNoDataMsg: "",
        name: _ctx.ParkingSlug
    }, {
        default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue.parkingSpaces, (parkingSpace) => {
                return (_openBlock(), _createBlock(_component_CollapsibleSubmoduleItem, {
                    title: parkingSpace.name,
                    key: parkingSpace.name
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_ParkingData, {
                            data: _ctx.parkingData(parkingSpace)
                        }, null, 8, ["data"]),
                        _cache[0] || (_cache[0] = _createElementVNode("hr", null, null, -1)),
                        _createVNode(_component_ParkingAccessRestrictions, {
                            data: _ctx.parkingAccessRestrictions(parkingSpace)
                        }, null, 8, ["data"])
                    ]),
                    _: 2
                }, 1032, ["title"]));
            }), 128))
        ]),
        _: 1
    }, 8, ["title", "hasData", "noDataMsg", "name"]));
}
