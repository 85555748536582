import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "ulp-information" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SubmodulesNavigation = _resolveComponent("SubmodulesNavigation");
    const _component_SiteInformation = _resolveComponent("SiteInformation");
    const _component_Instructions = _resolveComponent("Instructions");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoadingOrUnloadingPointActive)
            ? (_openBlock(), _createBlock(_component_SubmodulesNavigation, { key: 0 }))
            : _createCommentVNode("", true),
        (_ctx.isSiteInformationActive)
            ? (_openBlock(), _createBlock(_component_SiteInformation, { key: 1 }))
            : _createCommentVNode("", true),
        (_ctx.isInstructionActive)
            ? (_openBlock(), _createBlock(_component_Instructions, { key: 2 }))
            : _createCommentVNode("", true)
    ]));
}
