import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue";
const _hoisted_1 = { class: "column-title" };
const _hoisted_2 = {
    key: 0,
    class: "column-description"
};
const _hoisted_3 = { class: "column-header" };
const _hoisted_4 = { class: "column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Address = _resolveComponent("Address");
    const _component_MapsButton = _resolveComponent("MapsButton");
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("sub-modules.weighing-bridges.address")), 1),
        (!_ctx.hasData() && !_ctx.hasLocation(_ctx.data.location) && !_ctx.$store.state.ui.showIfNoData)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t("loady2go.no-data")), 1))
            : _createCommentVNode("", true),
        _createVNode(_component_Address, {
            address: _ctx.data.postalAddress
        }, null, 8, ["address"]),
        _createVNode(_component_MapsButton, {
            class: "mb-3",
            location: _ctx.data.location
        }, null, 8, ["location"]),
        _createVNode(_component_SubmoduleField, {
            hasData: !!_ctx.data.presence
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("sub-modules.weighing-bridges.bridge-presence")), 1),
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getValue(_ctx.data.presence, "WeighingBridgePresence")), 1)
            ]),
            _: 1
        }, 8, ["hasData"])
    ], 64));
}
