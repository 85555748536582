import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
    key: 0,
    class: "center-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SvgIcon = _resolveComponent("SvgIcon");
    return (_ctx.center)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_SvgIcon, {
                class: "loading-spinner",
                name: "loadingSpinner"
            })
        ]))
        : (_openBlock(), _createBlock(_component_SvgIcon, {
            key: 1,
            class: "loading-spinner",
            name: "loadingSpinner"
        }));
}
