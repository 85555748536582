import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "column-title" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayAttachments = _resolveComponent("DisplayAttachments");
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createBlock(_component_SubModule, {
        title: _ctx.$t('loady2go.sub-modules.site-navigation.name'),
        icon: "near_me",
        hasData: _ctx.hasData,
        noDataMsg: _ctx.$t('loady2go.no-data'),
        name: _ctx.SiteNavigationSlug
    }, {
        default: _withCtx(() => [
            _createVNode(_component_SubmoduleField, {
                hasData: _ctx.hasLocalisedAttachment(_ctx.modelValue)
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("sub-modules.site-navigation.components.title")), 1),
                    _createVNode(_component_DisplayAttachments, {
                        attachments: _ctx.modelValue,
                        title: _ctx.$t('sub-modules.site-navigation.components.navigation-apps')
                    }, null, 8, ["attachments", "title"])
                ]),
                _: 1
            }, 8, ["hasData"])
        ]),
        _: 1
    }, 8, ["title", "hasData", "noDataMsg", "name"]));
}
