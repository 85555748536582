import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "burger-menu" };
const _hoisted_2 = { class: "item-description" };
const _hoisted_3 = { class: "item-description" };
const _hoisted_4 = ["href"];
const _hoisted_5 = { class: "item-description" };
const _hoisted_6 = ["href"];
const _hoisted_7 = { class: "item-description" };
const _hoisted_8 = ["href"];
const _hoisted_9 = { class: "item-description" };
const _hoisted_10 = ["href"];
const _hoisted_11 = { class: "item-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
            class: "menu-item",
            onClick: _cache[0] || (_cache[0] =
                //@ts-ignore
                (...args) => (_ctx.setLanguage && _ctx.setLanguage(...args)))
        }, [
            _createVNode(_component_Icon, {
                class: "icon",
                name: "public"
            }),
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("core.components.top-navigation.menu-items.language")), 1)
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("hr", null, null, -1)),
        _createElementVNode("div", {
            class: "menu-item",
            onClick: _cache[1] || (_cache[1] =
                //@ts-ignore
                (...args) => (_ctx.setOptions && _ctx.setOptions(...args)))
        }, [
            _createVNode(_component_Icon, {
                class: "icon",
                name: "info"
            }),
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("loady2go.burger-menu.set-data-visibility")), 1)
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("hr", null, null, -1)),
        _createElementVNode("a", {
            href: _ctx.$t('core.components.top-navigation.legal-links-menu.contact.link'),
            target: "_blank",
            rel: "noopener noreferrer",
            class: "menu-item"
        }, [
            _createVNode(_component_Icon, {
                class: "icon",
                name: "message"
            }),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("core.components.top-navigation.legal-links-menu.contact.value")), 1)
        ], 8, _hoisted_4),
        _cache[4] || (_cache[4] = _createElementVNode("hr", null, null, -1)),
        _createElementVNode("a", {
            href: _ctx.$t('core.components.top-navigation.legal-links-menu.imprint.link'),
            target: "_blank",
            rel: "noopener noreferrer",
            class: "menu-item"
        }, [
            _createVNode(_component_Icon, {
                class: "icon",
                name: "fingerprint"
            }),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("core.components.top-navigation.legal-links-menu.imprint.value")), 1)
        ], 8, _hoisted_6),
        _cache[5] || (_cache[5] = _createElementVNode("hr", null, null, -1)),
        _createElementVNode("a", {
            href: _ctx.$t('core.components.top-navigation.legal-links-menu.faq.link'),
            target: "_blank",
            rel: "noopener noreferrer",
            class: "menu-item"
        }, [
            _createVNode(_component_Icon, {
                class: "icon",
                name: "quiz"
            }),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("core.components.top-navigation.legal-links-menu.faq.value")), 1)
        ], 8, _hoisted_8),
        _cache[6] || (_cache[6] = _createElementVNode("hr", null, null, -1)),
        _createElementVNode("a", {
            href: _ctx.$t('core.components.top-navigation.legal-links-menu.privacy-policy.link'),
            target: "_blank",
            rel: "noopener noreferrer",
            class: "menu-item"
        }, [
            _createVNode(_component_Icon, {
                class: "icon",
                name: "privacy_tip"
            }),
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("core.components.top-navigation.legal-links-menu.privacy-policy.value")), 1)
        ], 8, _hoisted_10),
        _cache[7] || (_cache[7] = _createElementVNode("hr", null, null, -1))
    ]));
}
