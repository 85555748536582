import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "column-header" };
const _hoisted_2 = { class: "column-description" };
const _hoisted_3 = { class: "column-header" };
const _hoisted_4 = { class: "column-description" };
const _hoisted_5 = { class: "column-header" };
const _hoisted_6 = { class: "column-description" };
const _hoisted_7 = {
    key: 0,
    class: "column-header"
};
const _hoisted_8 = {
    key: 1,
    class: "column-header"
};
const _hoisted_9 = { class: "column-description" };
const _hoisted_10 = {
    key: 0,
    class: "column-header"
};
const _hoisted_11 = {
    key: 1,
    class: "column-header"
};
const _hoisted_12 = { class: "column-description" };
const _hoisted_13 = {
    key: 0,
    class: "column-header"
};
const _hoisted_14 = {
    key: 1,
    class: "column-header"
};
const _hoisted_15 = { class: "column-description" };
const _hoisted_16 = {
    key: 0,
    class: "column-header"
};
const _hoisted_17 = {
    key: 1,
    class: "column-header"
};
const _hoisted_18 = { class: "column-description" };
const _hoisted_19 = {
    key: 0,
    class: "column-header"
};
const _hoisted_20 = {
    key: 1,
    class: "column-header"
};
const _hoisted_21 = { class: "column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    const _component_DisplayAttachments = _resolveComponent("DisplayAttachments");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createBlock(_component_SubModule, {
        title: _ctx.$t('sub-modules.general-instructions.name'),
        icon: "tips_and_updates",
        hasData: _ctx.hasData,
        noDataMsg: _ctx.$t('loady2go.no-data'),
        name: _ctx.GeneralInstructionsSlug
    }, {
        default: _withCtx(() => [
            _createVNode(_component_SubmoduleField, {
                hasData: _ctx.generalInstructions.productMustBeDeliveredSolo !== null
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("sub-modules.general-instructions.product-must-be-delivered-solo")), 1),
                    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.getTranslation(_ctx.generalInstructions.productMustBeDeliveredSolo, "yes", "no")), 1)
                ]),
                _: 1
            }, 8, ["hasData"]),
            _createVNode(_component_SubmoduleField, {
                hasData: !!_ctx.generalInstructions.containerHeldOnSite
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("sub-modules.general-instructions.container-held-on-site")), 1),
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getValue(_ctx.generalInstructions.containerHeldOnSite, "ContainerHeldOnSite")), 1)
                ]),
                _: 1
            }, 8, ["hasData"]),
            _createVNode(_component_SubmoduleField, {
                hasData: _ctx.generalInstructions.containerHeldOnSiteTimeInDays > 0
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("sub-modules.general-instructions.if-container-held-on-site")), 1),
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.containerHeldOnSiteTimeInDays), 1)
                ]),
                _: 1
            }, 8, ["hasData"]),
            _createVNode(_component_SubmoduleField, {
                hasData: !!_ctx.generalInstructions.timeLatestArrivalGuaranteedUnloadingLoadingTheSameDay
            }, {
                default: _withCtx(() => [
                    (_ctx.isLoadingPoint)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t("sub-modules.general-instructions.loading-time-of-latest-arrival")), 1))
                        : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t("sub-modules.general-instructions.unloading-time-of-latest-arrival")), 1)),
                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.generalInstructions.timeLatestArrivalGuaranteedUnloadingLoadingTheSameDay ?? "-"), 1)
                ]),
                _: 1
            }, 8, ["hasData"]),
            _createVNode(_component_SubmoduleField, {
                hasData: !!_ctx.generalInstructions.keyLocation
            }, {
                default: _withCtx(() => [
                    (_ctx.isLoadingPoint)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t("sub-modules.general-instructions.loading-location-of-truck-keys")), 1))
                        : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t("sub-modules.general-instructions.unloading-location-of-truck-keys")), 1)),
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.keyLocation), 1)
                ]),
                _: 1
            }, 8, ["hasData"]),
            _createVNode(_component_SubmoduleField, {
                hasData: !!_ctx.generalInstructions.operatorPresenceDuringLoadingUnloading
            }, {
                default: _withCtx(() => [
                    (_ctx.isLoadingPoint)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.$t("sub-modules.general-instructions.loading-operator-presence")), 1))
                        : (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(_ctx.$t("sub-modules.general-instructions.unloading-operator-presence")), 1)),
                    _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.getValue(_ctx.generalInstructions.operatorPresenceDuringLoadingUnloading, "PresenceAssistanceDuringLoadinUnloading")), 1)
                ]),
                _: 1
            }, 8, ["hasData"]),
            _createVNode(_component_SubmoduleField, {
                hasData: !!_ctx.generalInstructions.driverPresenceDuringLoadingUnloading
            }, {
                default: _withCtx(() => [
                    (_ctx.isLoadingPoint)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.$t("sub-modules.general-instructions.loading-driver-presence-required")), 1))
                        : (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.$t("sub-modules.general-instructions.unloading-driver-presence-required")), 1)),
                    _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.getValue(_ctx.generalInstructions.driverPresenceDuringLoadingUnloading, "PresenceAssistanceDuringLoadinUnloading")), 1)
                ]),
                _: 1
            }, 8, ["hasData"]),
            _createVNode(_component_SubmoduleField, {
                hasData: !!_ctx.generalInstructions.driverAssistanceDuringLoadingUnloading
            }, {
                default: _withCtx(() => [
                    (_ctx.isLoadingPoint)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_ctx.$t("sub-modules.general-instructions.loading-driver-assistance")), 1))
                        : (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(_ctx.$t("sub-modules.general-instructions.unloading-driver-assistance")), 1)),
                    _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.getValue(_ctx.generalInstructions.driverAssistanceDuringLoadingUnloading, "PresenceAssistanceDuringLoadinUnloading")), 1)
                ]),
                _: 1
            }, 8, ["hasData"]),
            _createVNode(_component_SubmoduleField, {
                hasData: !!_ctx.generalInstructions.additionalInformation.length
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_DisplayAttachments, {
                        attachments: _ctx.generalInstructions.additionalInformation,
                        title: _ctx.$t('sub-modules.general-instructions.additional-instructions')
                    }, null, 8, ["attachments", "title"])
                ]),
                _: 1
            }, 8, ["hasData"])
        ]),
        _: 1
    }, 8, ["title", "hasData", "noDataMsg", "name"]));
}
