import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "top-navigation" };
const _hoisted_2 = { class: "wrapper" };
const _hoisted_3 = { class: "logo" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_SvgIcon = _resolveComponent("SvgIcon");
    return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            (_ctx.isSubmoduleBackButtonActive)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] =
                        //@ts-ignore
                        (...args) => (_ctx.setSubmoduleNone && _ctx.setSubmoduleNone(...args))),
                    class: "button back-button"
                }, [
                    _createVNode(_component_Icon, { name: "arrow_back" })
                ]))
                : _createCommentVNode("", true),
            (!_ctx.isMenuActive && !_ctx.isLanguageActive && !_ctx.isOptionsActive)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    onClick: _cache[1] || (_cache[1] = () => _ctx.setMenuOpen(true)),
                    class: "button burger-menu"
                }, [
                    _createVNode(_component_Icon, { name: "menu" })
                ]))
                : (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    onClick: _cache[2] || (_cache[2] = () => _ctx.setMenuOpen(!_ctx.isMenuActive)),
                    class: "button burger-menu"
                }, [
                    _createVNode(_component_Icon, {
                        class: "icon-back",
                        name: "arrow_back_ios"
                    })
                ])),
            _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_SvgIcon, {
                    class: "loady2go",
                    name: "loady2go"
                })
            ])
        ])
    ]));
}
