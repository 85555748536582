import { createApp } from "vue";
import App from "./app.component.vue";
import "./registerServiceWorker";
import "./registerServiceWorkerUpdate";
import router from "./router";
import VueCookies from "vue-cookies";
import PrimeVue from "primevue/config";
import "./assets/styles/prime/_theme.scss";
import "primeicons/primeicons.css";
import "./assets/styles/styles.scss";
import "material-icons/iconfont/material-icons.css";
import { store } from "@/store/app.store";
import { createI18n } from "vue-i18n";
import { EnLocale, DeLocale, PlLocale, EsLocale, ItLocale, FrLocale, RoLocale } from "@/app/types/store.type";
import { translationService, setTranslation } from "@/app/shared/services/translation.service";
import localStorageService from "@/app/shared/services/local-storage.service";
const languageFromStorage = localStorageService.get("language");
store.commit("ui/setLanguage", !languageFromStorage ? EnLocale : languageFromStorage);
const showIfNoDataFromStorage = localStorageService.get("showIfNoData");
store.commit("ui/setShowIfNoData", !showIfNoDataFromStorage ? false : showIfNoDataFromStorage);
Promise.allSettled([
    translationService.fetchTranslation(EnLocale),
    translationService.fetchTranslation(DeLocale),
    translationService.fetchTranslation(PlLocale),
    translationService.fetchTranslation(EsLocale),
    translationService.fetchTranslation(ItLocale),
    translationService.fetchTranslation(FrLocale),
    translationService.fetchTranslation(RoLocale),
    translationService.fetchDictionary(EnLocale),
    translationService.fetchDictionary(DeLocale),
    translationService.fetchDictionary(PlLocale),
    translationService.fetchDictionary(EsLocale),
    translationService.fetchDictionary(ItLocale),
    translationService.fetchDictionary(FrLocale),
    translationService.fetchDictionary(RoLocale),
])
    .then(([enTranslation, deTranslation, plTranslation, esTranslation, itTranslation, frTranslation, roTranslation, enDictionary, deDictionary, plDictionary, esDictionary, itDictionary, frDictionary, roDictionary,]) => {
    setTranslation(enTranslation, enDictionary, EnLocale);
    setTranslation(deTranslation, deDictionary, DeLocale);
    setTranslation(plTranslation, plDictionary, PlLocale);
    setTranslation(esTranslation, esDictionary, EsLocale);
    setTranslation(itTranslation, itDictionary, ItLocale);
    setTranslation(frTranslation, frDictionary, FrLocale);
    setTranslation(roTranslation, roDictionary, RoLocale);
})
    .finally(() => {
    const i18n = createI18n({
        locale: EnLocale,
        messages: translationService.translations,
    });
    const app = createApp(App);
    app.config.globalProperties.$store = store;
    app.use(i18n);
    app.use(router);
    app.use(PrimeVue, { ripple: true });
    app.use(VueCookies);
    app.use(store);
    app.mount("#app");
});
