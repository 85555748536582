import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref, withCtx as _withCtx } from "vue";
import { computed, ref } from "vue";
import updateApplicationService from "@/app/core/services/update-application.service";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Icon from "@/app/shared/components/material-icon.component.vue";
import LoadingSpinner from "@/app/shared/components/loading-spinner.component.vue";
import { useStore } from "vuex";
import _ from "lodash";
export default /*@__PURE__*/ _defineComponent({
    __name: 'update-prompt-modal',
    setup(__props) {
        const store = useStore();
        // NOTE: showing a loading-spinner for `updating` can block `window.location.reload()`
        const updating = ref(false);
        const loading = computed(() => _.values(store.state.summary.loadingStateById).some((it) => it));
        const onClick = () => {
            updateApplicationService.update();
            updating.value = true;
        };
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_unref(Dialog), {
                visible: _ctx.$store.state.core.appUpdatePending,
                "onUpdate:visible": _cache[0] || (_cache[0] = ($event) => ((_ctx.$store.state.core.appUpdatePending) = $event)),
                "show-header": false,
                modal: true,
                "close-on-escape": false
            }, {
                default: _withCtx(() => [
                    _createVNode(Icon, {
                        class: "info",
                        variant: "outlined",
                        name: "info"
                    }),
                    _createElementVNode("h4", null, _toDisplayString(_ctx.$t("loady2go.modals.update-prompt.title")), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t("loady2go.modals.update-prompt.description")), 1),
                    _createVNode(_unref(Button), {
                        class: "button",
                        disabled: loading.value || updating.value,
                        onClick: onClick
                    }, {
                        default: _withCtx(() => [
                            (loading.value)
                                ? (_openBlock(), _createBlock(LoadingSpinner, {
                                    key: 0,
                                    center: ""
                                }))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _createTextVNode(_toDisplayString(_ctx.$t("loady2go.modals.update-prompt.button")), 1)
                                ], 64))
                        ]),
                        _: 1
                    }, 8, ["disabled"])
                ]),
                _: 1
            }, 8, ["visible"]));
        };
    }
});
