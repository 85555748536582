import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "overlay-panel-content" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_SortButtonOption = _resolveComponent("SortButtonOption");
    const _component_Popover = _resolveComponent("Popover");
    return (_openBlock(), _createElementBlock("div", {
        class: "sort-button",
        onClick: _cache[6] || (_cache[6] =
            //@ts-ignore
            (...args) => (_ctx.toggle && _ctx.toggle(...args)))
    }, [
        _createVNode(_component_Icon, { name: "sort" }),
        _createElementVNode("div", null, _toDisplayString(_ctx.buttonLabel), 1),
        _createVNode(_component_Popover, {
            ref: "overlayPanel",
            onHide: _cache[4] || (_cache[4] = ($event) => (_ctx.isVisible = false)),
            onShow: _cache[5] || (_cache[5] = ($event) => (_ctx.isVisible = true))
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_SortButtonOption, {
                        modelValue: _ctx.model,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model) = $event)),
                        value: _ctx.SortOption.MostRecent,
                        label: _ctx.$t('loady2go.order-list.most-recent'),
                        isActive: _ctx.isActive(_ctx.SortOption.MostRecent),
                        onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.setSortBy(_ctx.SortOption.MostRecent)))
                    }, null, 8, ["modelValue", "value", "label", "isActive"]),
                    _createVNode(_component_SortButtonOption, {
                        modelValue: _ctx.model,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.model) = $event)),
                        value: _ctx.SortOption.Oldest,
                        label: _ctx.$t('loady2go.order-list.oldest'),
                        isActive: _ctx.isActive(_ctx.SortOption.Oldest),
                        onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.setSortBy(_ctx.SortOption.Oldest)))
                    }, null, 8, ["modelValue", "value", "label", "isActive"])
                ])
            ]),
            _: 1
        }, 512)
    ]));
}
