import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "bottom-navigation" };
const _hoisted_2 = { class: "wrapper" };
const _hoisted_3 = { class: "container" };
const _hoisted_4 = { class: "text" };
const _hoisted_5 = {
    key: 1,
    class: "empty-box"
};
const _hoisted_6 = { class: "home-container" };
const _hoisted_7 = { class: "text" };
const _hoisted_8 = {
    key: 3,
    class: "empty-box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SvgIcon = _resolveComponent("SvgIcon");
    const _component_Icon = _resolveComponent("Icon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                (_ctx.hasCompanyFrom)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(["box", { 'is-active': _ctx.isLoadingPointActive }]),
                        onClick: _cache[0] || (_cache[0] =
                            //@ts-ignore
                            (...args) => (_ctx.setLoadingPoint && _ctx.setLoadingPoint(...args)))
                    }, [
                        _createVNode(_component_SvgIcon, {
                            class: "icon",
                            name: "loadingPoint"
                        }),
                        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("domains.loading-point.short")), 1)
                    ], 2))
                    : (_openBlock(), _createElementBlock("div", _hoisted_5)),
                _createElementVNode("div", {
                    class: _normalizeClass(["box home-box", { 'is-active': _ctx.isHomeActive }])
                }, [
                    _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", {
                            class: "home-icon",
                            onClick: _cache[1] || (_cache[1] =
                                //@ts-ignore
                                (...args) => (_ctx.goToHome && _ctx.goToHome(...args)))
                        }, [
                            _createVNode(_component_Icon, {
                                class: "icon icon-home",
                                name: "home"
                            })
                        ])
                    ])
                ], 2),
                (_ctx.hasCompanyTo)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        class: _normalizeClass(["box", { 'is-active': _ctx.isUnloadingPointActive }]),
                        onClick: _cache[2] || (_cache[2] =
                            //@ts-ignore
                            (...args) => (_ctx.setUnloadingPoint && _ctx.setUnloadingPoint(...args)))
                    }, [
                        _createVNode(_component_SvgIcon, {
                            class: "icon",
                            name: "unloadingPoint"
                        }),
                        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("domains.unloading-point.short")), 1)
                    ], 2))
                    : (_openBlock(), _createElementBlock("div", _hoisted_8))
            ])
        ])
    ]));
}
