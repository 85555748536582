import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment } from "vue";
const _hoisted_1 = { class: "column-title" };
const _hoisted_2 = {
    key: 0,
    class: "column-description"
};
const _hoisted_3 = { class: "column-header" };
const _hoisted_4 = { class: "column-header" };
const _hoisted_5 = { class: "column-description" };
const _hoisted_6 = { class: "column-header" };
const _hoisted_7 = {
    key: 0,
    class: "chip-container"
};
const _hoisted_8 = {
    key: 1,
    class: "column-description"
};
const _hoisted_9 = { class: "column-header" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Address = _resolveComponent("Address");
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    const _component_MapsButton = _resolveComponent("MapsButton");
    const _component_OpeningHours = _resolveComponent("OpeningHours");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("sub-modules.physical-check-in.components.display-physical-check-in.physical-check-in-title")), 1),
        (!_ctx.hasData() && !_ctx.$store.state.ui.showIfNoData)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t("loady2go.no-data")), 1))
            : _createCommentVNode("", true),
        _createVNode(_component_SubmoduleField, {
            hasData: !!_ctx.addressArray.length
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("sub-modules.physical-check-in.components.display-physical-check-in.first-reception")), 1),
                _createVNode(_component_Address, {
                    address: _ctx.presenceCheckIn.postalAddress,
                    short: ""
                }, null, 8, ["address"])
            ]),
            _: 1
        }, 8, ["hasData"]),
        _createVNode(_component_MapsButton, {
            location: _ctx.presenceCheckIn.location
        }, null, 8, ["location"]),
        _createVNode(_component_SubmoduleField, {
            hasData: !!_ctx.presenceCheckIn.languagesRegistrationOffices.length
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("sub-modules.physical-check-in.components.display-physical-check-in.language-title")), 1),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getValues(_ctx.presenceCheckIn.languagesRegistrationOffices, "Languages")), 1)
            ]),
            _: 1
        }, 8, ["hasData"]),
        _createVNode(_component_SubmoduleField, {
            hasData: !!_ctx.presenceCheckIn.driverIdentificationRequirement.length
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("sub-modules.physical-check-in.components.display-physical-check-in.driver-identity")), 1),
                (_ctx.identities && _ctx.identities.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.identities, (identity) => {
                            return (_openBlock(), _createElementBlock("span", {
                                key: identity,
                                class: "chip"
                            }, _toDisplayString(identity), 1));
                        }), 128))
                    ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_8, "-"))
            ]),
            _: 1
        }, 8, ["hasData"]),
        _createVNode(_component_SubmoduleField, {
            hasData: !!_ctx.openingHoursArray.length
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("shared.components.opening-hours-widget.title")), 1),
                _createVNode(_component_OpeningHours, {
                    openingHours: _ctx.presenceCheckIn.openingHours
                }, null, 8, ["openingHours"])
            ]),
            _: 1
        }, 8, ["hasData"])
    ], 64));
}
