import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "instructions" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_GeneralInstructions = _resolveComponent("GeneralInstructions");
    const _component_DriverBriefing = _resolveComponent("DriverBriefing");
    const _component_Weighing = _resolveComponent("Weighing");
    const _component_Sampling = _resolveComponent("Sampling");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.generalInstructionsHasData() || _ctx.areFieldsShown)
            ? (_openBlock(), _createBlock(_component_GeneralInstructions, {
                key: 0,
                generalInstructions: _ctx.data.instruction.generalInstructions,
                hasData: _ctx.generalInstructionsHasData()
            }, null, 8, ["generalInstructions", "hasData"]))
            : _createCommentVNode("", true),
        (_ctx.driverBriefingHaData() || _ctx.areFieldsShown)
            ? (_openBlock(), _createBlock(_component_DriverBriefing, {
                key: 1,
                modelValue: _ctx.data.instruction.driverBriefing,
                hasData: _ctx.driverBriefingHaData()
            }, null, 8, ["modelValue", "hasData"]))
            : _createCommentVNode("", true),
        (_ctx.weighingHasData() || _ctx.areFieldsShown)
            ? (_openBlock(), _createBlock(_component_Weighing, {
                key: 2,
                modelValue: _ctx.data.instruction.weighing,
                hasData: _ctx.weighingHasData()
            }, null, 8, ["modelValue", "hasData"]))
            : _createCommentVNode("", true),
        (_ctx.samplingHasData() || _ctx.areFieldsShown)
            ? (_openBlock(), _createBlock(_component_Sampling, {
                key: 3,
                modelValue: _ctx.data.instruction.sampling,
                hasData: _ctx.samplingHasData()
            }, null, 8, ["modelValue", "hasData"]))
            : _createCommentVNode("", true)
    ]));
}
