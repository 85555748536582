import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AccessRestriction = _resolveComponent("AccessRestriction");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createBlock(_component_SubModule, {
        title: _ctx.$t('loady2go.sub-modules.access-restrictions.site-access-restrictions'),
        icon: "local_shipping",
        hasData: _ctx.hasData,
        noDataMsg: _ctx.$t('loady2go.no-data'),
        name: _ctx.SiteAccessRestrictionsSlug
    }, {
        default: _withCtx(() => [
            _createVNode(_component_AccessRestriction, { accessRestriction: _ctx.modelValue }, null, 8, ["accessRestriction"])
        ]),
        _: 1
    }, 8, ["title", "hasData", "noDataMsg", "name"]));
}
