import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "d-flex" };
const _hoisted_2 = { class: "column-header mb-0" };
const _hoisted_3 = { class: "column-description mb-0" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_Icon = _resolveComponent("Icon");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createBlock(_component_SubModule, {
        title: _ctx.$t('loady2go.sub-modules.infrastracture-near-site.name'),
        icon: "storefront",
        hasData: !!_ctx.infoConfig.length,
        noDataMsg: _ctx.$t('sub-modules.nearby-infrastructures.components.display-infrastructures-nearby.no-infrastructures'),
        showNoDataMsg: "",
        name: _ctx.InfrastructureSlug
    }, {
        default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.infoConfig, (info) => {
                return (_openBlock(), _createElementBlock("div", {
                    key: info.translateKey,
                    class: "item"
                }, [
                    _createVNode(_component_Icon, {
                        name: info.iconName
                    }, null, 8, ["name"]),
                    _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t(info.translateKey)) + " -", 1),
                        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getInfoData(info.nameValue)) + " km", 1)
                    ])
                ]));
            }), 128))
        ]),
        _: 1
    }, 8, ["title", "hasData", "noDataMsg", "name"]));
}
