import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = { class: "access-restriction" };
const _hoisted_2 = {
    key: 0,
    class: "column-description"
};
const _hoisted_3 = {
    key: 1,
    class: "container"
};
const _hoisted_4 = { class: "item" };
const _hoisted_5 = { class: "column-header" };
const _hoisted_6 = { class: "column-description" };
const _hoisted_7 = { class: "item" };
const _hoisted_8 = { class: "column-header" };
const _hoisted_9 = { class: "column-description" };
const _hoisted_10 = { class: "item" };
const _hoisted_11 = { class: "column-header" };
const _hoisted_12 = { class: "column-description" };
const _hoisted_13 = { class: "item" };
const _hoisted_14 = { class: "column-header" };
const _hoisted_15 = { class: "column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_DisplayAttachments = _resolveComponent("DisplayAttachments");
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.accessRestriction?.noRestrictions)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t("shared.components.access-restriction.form-fields.no-restrictions.label")), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("shared.components.access-restriction.form-fields.height.label")), 1),
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.accessRestriction?.height ? _ctx.accessRestriction?.height + " m" : "-"), 1)
                ]),
                _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t("shared.components.access-restriction.form-fields.length.label")), 1),
                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.accessRestriction?.length ? _ctx.accessRestriction?.length + " m" : "-"), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("shared.components.access-restriction.form-fields.width.label")), 1),
                    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.accessRestriction?.width ? _ctx.accessRestriction?.width + " m" : "-"), 1)
                ]),
                _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t("shared.components.access-restriction.form-fields.weight.label")), 1),
                    _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.accessRestriction?.weight ? _ctx.accessRestriction?.weight + " t" : "-"), 1)
                ])
            ])),
        _createVNode(_component_SubmoduleField, {
            hasData: !!_ctx.accessRestriction.additionalRestrictions.length
        }, {
            default: _withCtx(() => [
                _createVNode(_component_DisplayAttachments, {
                    attachments: _ctx.accessRestriction.additionalRestrictions,
                    title: _ctx.$t('global.phrases.additional-restrictions')
                }, null, 8, ["attachments", "title"])
            ]),
            _: 1
        }, 8, ["hasData"])
    ]));
}
