import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_PhysicalCheckIn = _resolveComponent("PhysicalCheckIn");
    const _component_OnlineCheckIn = _resolveComponent("OnlineCheckIn");
    const _component_CollapsibleSubmoduleItem = _resolveComponent("CollapsibleSubmoduleItem");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createBlock(_component_SubModule, {
        title: _ctx.$t('loady2go.sub-modules.check-in.name'),
        icon: "meeting_room",
        hasData: _ctx.hasData,
        noDataMsg: _ctx.$t('sub-modules.check-in.empty'),
        showNoDataMsg: "",
        name: _ctx.CheckInSlug
    }, {
        default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue.checkIns, (checkIn) => {
                return (_openBlock(), _createBlock(_component_CollapsibleSubmoduleItem, {
                    title: checkIn.name,
                    key: checkIn.name
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_PhysicalCheckIn, {
                            presenceCheckIn: checkIn.presenceCheckIn
                        }, null, 8, ["presenceCheckIn"]),
                        _cache[0] || (_cache[0] = _createElementVNode("hr", null, null, -1)),
                        _createVNode(_component_OnlineCheckIn, {
                            onlineCheckIn: checkIn.onlineCheckIn
                        }, null, 8, ["onlineCheckIn"]),
                        _cache[1] || (_cache[1] = _createElementVNode("hr", null, null, -1))
                    ]),
                    _: 2
                }, 1032, ["title"]));
            }), 128))
        ]),
        _: 1
    }, 8, ["title", "hasData", "noDataMsg", "name"]));
}
