import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "loading-overlay" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SvgIcon = _resolveComponent("SvgIcon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_SvgIcon, {
            class: "logo",
            name: "logo"
        })
    ]));
}
