import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "site-information" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SiteAddress = _resolveComponent("SiteAddress");
    const _component_SiteNavigation = _resolveComponent("SiteNavigation");
    const _component_SiteOpeningHours = _resolveComponent("SiteOpeningHours");
    const _component_UlpOpeningHours = _resolveComponent("UlpOpeningHours");
    const _component_SiteAccessRestrictions = _resolveComponent("SiteAccessRestrictions");
    const _component_LoadingUnloadingAccessRestrictions = _resolveComponent("LoadingUnloadingAccessRestrictions");
    const _component_SitePPERequirements = _resolveComponent("SitePPERequirements");
    const _component_CheckIn = _resolveComponent("CheckIn");
    const _component_Parking = _resolveComponent("Parking");
    const _component_WeighingBridge = _resolveComponent("WeighingBridge");
    const _component_Infrastructure = _resolveComponent("Infrastructure");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.siteAddressHasData() || _ctx.areFieldsShown)
            ? (_openBlock(), _createBlock(_component_SiteAddress, {
                key: 0,
                modelValue: _ctx.data.address.address,
                hasData: _ctx.siteAddressHasData()
            }, null, 8, ["modelValue", "hasData"]))
            : _createCommentVNode("", true),
        (_ctx.siteNavigationHasData() || _ctx.areFieldsShown)
            ? (_openBlock(), _createBlock(_component_SiteNavigation, {
                key: 1,
                modelValue: _ctx.data.address.siteNavigation,
                hasData: _ctx.siteNavigationHasData()
            }, null, 8, ["modelValue", "hasData"]))
            : _createCommentVNode("", true),
        (_ctx.siteOpeningHoursHasData() || _ctx.areFieldsShown)
            ? (_openBlock(), _createBlock(_component_SiteOpeningHours, {
                key: 2,
                modelValue: _ctx.data.openingHour.openingHours,
                hasData: _ctx.siteOpeningHoursHasData()
            }, null, 8, ["modelValue", "hasData"]))
            : _createCommentVNode("", true),
        (_ctx.ulpOpeningHoursHasData() || _ctx.areFieldsShown)
            ? (_openBlock(), _createBlock(_component_UlpOpeningHours, {
                key: 3,
                modelValue: _ctx.data.openingHour.unloadingLoadingHours,
                hasData: _ctx.ulpOpeningHoursHasData()
            }, null, 8, ["modelValue", "hasData"]))
            : _createCommentVNode("", true),
        (_ctx.siteAccessRestrictionHasData() || _ctx.areFieldsShown)
            ? (_openBlock(), _createBlock(_component_SiteAccessRestrictions, {
                key: 4,
                modelValue: _ctx.data.accessRestriction.siteAccessRestriction,
                hasData: _ctx.siteAccessRestrictionHasData()
            }, null, 8, ["modelValue", "hasData"]))
            : _createCommentVNode("", true),
        (_ctx.ulpAccessRestrictionHasData() || _ctx.areFieldsShown)
            ? (_openBlock(), _createBlock(_component_LoadingUnloadingAccessRestrictions, {
                key: 5,
                modelValue: _ctx.data.accessRestriction.unloadingLoadingPointAccessRestriction,
                hasData: _ctx.ulpAccessRestrictionHasData()
            }, null, 8, ["modelValue", "hasData"]))
            : _createCommentVNode("", true),
        (_ctx.ppeHasData() || _ctx.areFieldsShown)
            ? (_openBlock(), _createBlock(_component_SitePPERequirements, {
                key: 6,
                modelValue: _ctx.data.vehicleAndPersonalProtectiveEquipment.vehicleAndPersonalProtectiveEquipment,
                hasData: _ctx.ppeHasData()
            }, null, 8, ["modelValue", "hasData"]))
            : _createCommentVNode("", true),
        (!!_ctx.data.checkIn.checkIns.length || _ctx.areFieldsShown)
            ? (_openBlock(), _createBlock(_component_CheckIn, {
                key: 7,
                modelValue: _ctx.data.checkIn,
                hasData: !!_ctx.data.checkIn.checkIns.length
            }, null, 8, ["modelValue", "hasData"]))
            : _createCommentVNode("", true),
        (!!_ctx.data.parking.parkingSpaces.length || _ctx.areFieldsShown)
            ? (_openBlock(), _createBlock(_component_Parking, {
                key: 8,
                modelValue: _ctx.data.parking,
                hasData: !!_ctx.data.parking.parkingSpaces.length
            }, null, 8, ["modelValue", "hasData"]))
            : _createCommentVNode("", true),
        (!!_ctx.data.weighingBridge.weighingBridges.length || _ctx.areFieldsShown)
            ? (_openBlock(), _createBlock(_component_WeighingBridge, {
                key: 9,
                modelValue: _ctx.data.weighingBridge,
                hasData: !!_ctx.data.weighingBridge.weighingBridges.length
            }, null, 8, ["modelValue", "hasData"]))
            : _createCommentVNode("", true),
        (_ctx.InfrastructureHasData() || _ctx.areFieldsShown)
            ? (_openBlock(), _createBlock(_component_Infrastructure, {
                key: 10,
                modelValue: _ctx.data.infrastructure,
                hasData: _ctx.InfrastructureHasData()
            }, null, 8, ["modelValue", "hasData"]))
            : _createCommentVNode("", true)
    ]));
}
