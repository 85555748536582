import moment from "moment";
import { createRouter, createWebHashHistory, } from "vue-router";
import { store } from "@/store/app.store";
import { NavigationModule, NavigationSubmodule } from "@/app/types/store.type";
import { CheckInSlug, DriverBriefingSlug, GeneralInstructionsSlug, InfrastructureSlug, ParkingSlug, PpeRequirementsSlug, SamplingSlug, SiteAccessRestrictionsSlug, SiteAddressSlug, SiteNavigationSlug, SiteOpeningHoursSlug, UlpAccessRestrictionsSlug, UlpOpeningHoursSlug, WeighingSlug, WeighingBridgeSlug, SummaryType, } from "@/router/router.constants";
import FallbackViewComponent from "@/app/views/fallback-view.component.vue";
import UlpInformationComponent from "@/app/views/ulp-information.component.vue";
import OrderListComponent from "@/app/views/order-list/order-list.component.vue";
const subModulesMapping = {
    [GeneralInstructionsSlug]: NavigationSubmodule.Instructions,
    [DriverBriefingSlug]: NavigationSubmodule.Instructions,
    [WeighingSlug]: NavigationSubmodule.Instructions,
    [SamplingSlug]: NavigationSubmodule.Instructions,
    [SiteAddressSlug]: NavigationSubmodule.SiteInformation,
    [SiteNavigationSlug]: NavigationSubmodule.SiteInformation,
    [SiteOpeningHoursSlug]: NavigationSubmodule.SiteInformation,
    [UlpOpeningHoursSlug]: NavigationSubmodule.SiteInformation,
    [SiteAccessRestrictionsSlug]: NavigationSubmodule.SiteInformation,
    [UlpAccessRestrictionsSlug]: NavigationSubmodule.SiteInformation,
    [PpeRequirementsSlug]: NavigationSubmodule.SiteInformation,
    [CheckInSlug]: NavigationSubmodule.SiteInformation,
    [ParkingSlug]: NavigationSubmodule.SiteInformation,
    [WeighingBridgeSlug]: NavigationSubmodule.SiteInformation,
    [InfrastructureSlug]: NavigationSubmodule.SiteInformation,
};
const routes = [
    {
        path: "/",
        name: "home",
        redirect: "order-list",
    },
    {
        path: "/fallback",
        name: "fallback",
        component: FallbackViewComponent,
    },
    {
        path: "/order-list",
        name: "order-list",
        component: OrderListComponent,
    },
    {
        path: "/:id",
        name: "id",
        component: UlpInformationComponent,
        beforeEnter: fetchSummary,
        children: [
            {
                path: ":loadingType",
                name: "ModuleView",
                component: UlpInformationComponent,
                beforeEnter: selectSummary,
                children: [
                    {
                        path: ":subModuleId",
                        name: "SubModuleView",
                        component: UlpInformationComponent,
                        beforeEnter: navigateToSubModule,
                    },
                ],
            },
        ],
    },
];
async function fetchSummary(to, from, next) {
    const path = to.params.id;
    if (!path || path === "/") {
        return false;
    }
    store.commit("summary/setActiveSummary", { id: path });
    let summary = store.getters["summary/getActive"];
    if (summary === undefined) {
        await store.dispatch("summary/fetchSummaryById", { id: path });
        if (store.state.summary.loadingErrorById[path]) {
            return next("order-list");
        }
        summary = store.getters["summary/getActive"];
        const laneId = summary.mainInformation.laneId;
        const creationDate = summary.mainInformation.creationDate;
        const filteredSummaryInfoIds = Object.fromEntries(Object.entries(store.state.summary.summaryInfoIds).filter(([key]) => key !== path));
        for (const [id, summaryInfo] of Object.entries(filteredSummaryInfoIds)) {
            if (summaryInfo.laneId === laneId) {
                if (moment(summaryInfo.creationDate).isAfter(moment(creationDate))) {
                    store.commit("summary/removeSummaryId", { id: path });
                    return next({ name: "id", params: { id: id } });
                }
                else {
                    store.commit("summary/removeSummaryId", { id: id });
                }
            }
        }
    }
    if (summary && moment().isSameOrAfter(summary?.mainInformation?.validityDate)) {
        return next("order-list");
    }
    if (!summary.mainInformation.companyFromName) {
        store.commit("ui/setNavigationModules", NavigationModule.UnloadingPoint);
    }
    return next();
}
function selectSummary(to, from, next) {
    const id = to.params.id;
    const loadingTypeParam = to.params.loadingType;
    const validLoadingTypes = [SummaryType.Loading, SummaryType.Unloading];
    const isLoadingTypeWrong = !validLoadingTypes.includes(loadingTypeParam);
    if (isLoadingTypeWrong) {
        return next({ name: "id", params: { id } });
    }
    const isLoadingSummary = loadingTypeParam === SummaryType.Loading;
    const isUnloadingSummary = loadingTypeParam === SummaryType.Unloading;
    const summary = store.getters["summary/getActive"];
    const hasLoadingSummaryData = summary.mainInformation.companyFromName;
    const hasUnloadingSummaryData = summary.mainInformation.companyToName;
    if (isLoadingSummary && hasLoadingSummaryData) {
        store.commit("ui/setNavigationModules", NavigationModule.LoadingPoint);
    }
    if (isUnloadingSummary && hasUnloadingSummaryData) {
        store.commit("ui/setNavigationModules", NavigationModule.UnloadingPoint);
    }
    return next();
}
function navigateToSubModule(to, from, next) {
    const subModuleId = to.params.subModuleId;
    const id = to.params.id;
    const subModuleExists = subModulesMapping[subModuleId];
    if (subModuleExists) {
        setStoreOptions(to, subModuleId);
        scrollToElement(subModuleId);
        return next();
    }
    return next({ name: "id", params: { id } });
}
function scrollToElement(id) {
    setTimeout(() => {
        const element = document.getElementById(id);
        element?.scrollIntoView({ behavior: "smooth" });
    }, 350);
}
function setStoreOptions(to, subModuleId) {
    store.commit("ui/setShowIfNoData", true); //Enable submodules visibility
    store.commit("ui/setNavigationSubmodules", subModulesMapping[subModuleId]); // Set active view
}
const router = createRouter({
    history: createWebHashHistory(),
    routes,
});
export default router;
