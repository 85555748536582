import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "container" };
const _hoisted_2 = { class: "wrapper" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_InstallPrompt = _resolveComponent("InstallPrompt");
    const _component_UpdatePromptModal = _resolveComponent("UpdatePromptModal");
    const _component_TopNavigation = _resolveComponent("TopNavigation");
    const _component_LoadingOverlay = _resolveComponent("LoadingOverlay");
    const _component_DisplayHeader = _resolveComponent("DisplayHeader");
    const _component_BurgerMenu = _resolveComponent("BurgerMenu");
    const _component_LanguageMenu = _resolveComponent("LanguageMenu");
    const _component_OptionsMenu = _resolveComponent("OptionsMenu");
    const _component_router_view = _resolveComponent("router-view");
    const _component_BottomNavigation = _resolveComponent("BottomNavigation");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_InstallPrompt),
        _createVNode(_component_UpdatePromptModal),
        _createVNode(_component_TopNavigation),
        (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_LoadingOverlay, { key: 0 }))
            : _createCommentVNode("", true),
        _createElementVNode("main", {
            id: "main",
            class: _normalizeClass({ 'is-bottom-nav-not-active': !_ctx.displayBottomNav })
        }, [
            _createVNode(_component_DisplayHeader),
            _createElementVNode("div", _hoisted_2, [
                (_ctx.isMenuActive)
                    ? (_openBlock(), _createBlock(_component_BurgerMenu, { key: 0 }))
                    : (_ctx.isLanguageActive)
                        ? (_openBlock(), _createBlock(_component_LanguageMenu, { key: 1 }))
                        : (_ctx.isOptionsActive)
                            ? (_openBlock(), _createBlock(_component_OptionsMenu, { key: 2 }))
                            : (_openBlock(), _createBlock(_component_router_view, { key: 3 }))
            ])
        ], 2),
        (_ctx.displayBottomNav)
            ? (_openBlock(), _createBlock(_component_BottomNavigation, { key: 1 }))
            : _createCommentVNode("", true)
    ]));
}
