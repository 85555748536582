import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "order-list" };
const _hoisted_2 = { class: "header" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SortButton = _resolveComponent("SortButton");
    const _component_OrderCard = _resolveComponent("OrderCard");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h3", null, _toDisplayString(_ctx.$t("loady2go.top-navigation.order-list")), 1),
            _createVNode(_component_SortButton)
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedOrderIds, (id) => {
            return (_openBlock(), _createBlock(_component_OrderCard, {
                key: id,
                class: "card",
                "summary-id": id
            }, null, 8, ["summary-id"]));
        }), 128))
    ]));
}
