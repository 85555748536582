import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "order-product" };
const _hoisted_2 = { class: "product-name" };
const _hoisted_3 = { class: "order-sites" };
const _hoisted_4 = { class: "sites" };
const _hoisted_5 = { class: "site" };
const _hoisted_6 = { class: "label" };
const _hoisted_7 = { class: "value" };
const _hoisted_8 = { class: "site" };
const _hoisted_9 = { class: "label mb-0" };
const _hoisted_10 = { class: "value mb-0" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LoadingSpinner = _resolveComponent("LoadingSpinner");
    const _component_Icon = _resolveComponent("Icon");
    const _component_OrderCardModal = _resolveComponent("OrderCardModal");
    const _component_Thumbnail = _resolveComponent("Thumbnail");
    const _component_SvgIcon = _resolveComponent("SvgIcon");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["order-card", { 'is-not-valid': _ctx.isInvalid || _ctx.hasError }]),
        onClick: _cache[1] || (_cache[1] = () => _ctx.onClick())
    }, [
        (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                key: 0,
                center: ""
            }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("div", {
                    class: "action-icon",
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.openModal($event)))
                }, [
                    _createVNode(_component_Icon, {
                        class: "info",
                        variant: "outlined",
                        name: _ctx.iconName
                    }, null, 8, ["name"]),
                    _createVNode(_component_OrderCardModal, {
                        onCloseModal: _ctx.closeModal,
                        onDeleteOrder: _ctx.deleteOrder,
                        summaryId: _ctx.summaryId,
                        isInvalid: _ctx.isInvalid
                    }, null, 8, ["onCloseModal", "onDeleteOrder", "summaryId", "isInvalid"])
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(["container", { 'is-not-valid-item': _ctx.isInvalid || _ctx.hasError }])
                }, [
                    _createElementVNode("div", _hoisted_1, [
                        _createVNode(_component_Thumbnail, {
                            src: _ctx.summary?.companyFromLogoPath,
                            icon: "domain"
                        }, null, 8, ["src"]),
                        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.summary?.productName ?? "-"), 1)
                    ]),
                    _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_SvgIcon, {
                            class: "icon",
                            name: "orderCard"
                        }),
                        _createElementVNode("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, [
                                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("loady2go.order-list.from-site")), 1),
                                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.summary?.siteFromName ?? "-"), 1)
                            ]),
                            _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("loady2go.order-list.to-site")), 1),
                                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.summary?.siteToName ?? "-"), 1)
                            ])
                        ])
                    ])
                ], 2)
            ], 64))
    ], 2));
}
