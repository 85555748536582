import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    key: 1,
    class: "column-description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_ImageSlider = _resolveComponent("ImageSlider");
    const _component_DisplayAttachments = _resolveComponent("DisplayAttachments");
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createBlock(_component_SubModule, {
        title: _ctx.$t('sub-modules.driver-briefing.name'),
        icon: "menu_book",
        hasData: _ctx.hasData,
        noDataMsg: _ctx.$t('loady2go.no-data'),
        name: "driver-briefing"
    }, {
        default: _withCtx(() => [
            (_ctx.areImagesAvailable)
                ? (_openBlock(), _createBlock(_component_ImageSlider, {
                    key: 0,
                    class: "mb-3",
                    images: _ctx.getLocalImages
                }, null, 8, ["images"]))
                : _createCommentVNode("", true),
            _createVNode(_component_SubmoduleField, {
                hasData: _ctx.hasLocalisedAttachment(_ctx.modelValue.driverBriefingInformation)
            }, {
                default: _withCtx(() => [
                    _createVNode(_component_DisplayAttachments, {
                        attachments: _ctx.modelValue.driverBriefingInformation,
                        isDriverBriefing: ""
                    }, null, 8, ["attachments"])
                ]),
                _: 1
            }, 8, ["hasData"]),
            (_ctx.areImagesAvailable)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t("loady2go.sub-modules.driver-briefing.fullscreen")), 1))
                : _createCommentVNode("", true)
        ]),
        _: 1
    }, 8, ["title", "hasData", "noDataMsg"]));
}
