import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "swiper-zoom-container" };
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SwiperSlide = _resolveComponent("SwiperSlide");
    const _component_Swiper = _resolveComponent("Swiper");
    return (_openBlock(), _createBlock(_component_Swiper, {
        style: {
            '--swiper-navigation-color': '#fff',
            '--swiper-pagination-color': '#fff',
        },
        zoom: true,
        navigation: true,
        pagination: {
            clickable: true,
        },
        modules: _ctx.modules
    }, {
        default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (images) => {
                return (_openBlock(), _createBlock(_component_SwiperSlide, { key: images }, {
                    default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                            _createElementVNode("img", { src: images }, null, 8, _hoisted_2)
                        ])
                    ]),
                    _: 2
                }, 1024));
            }), 128))
        ]),
        _: 1
    }, 8, ["modules"]));
}
