import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "column-title" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_WeighingBridgeData = _resolveComponent("WeighingBridgeData");
    const _component_OpeningHours = _resolveComponent("OpeningHours");
    const _component_CollapsibleSubmoduleItem = _resolveComponent("CollapsibleSubmoduleItem");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createBlock(_component_SubModule, {
        title: _ctx.$t('loady2go.sub-modules.weighing-bridge.name'),
        isSvg: true,
        icon: "weighingBridges",
        hasData: _ctx.hasData,
        noDataMsg: _ctx.$t('sub-modules.weighing-bridges.empty'),
        showNoDataMsg: "",
        name: _ctx.WeighingBridgeSlug
    }, {
        default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue.weighingBridges, (weighingBridge) => {
                return (_openBlock(), _createBlock(_component_CollapsibleSubmoduleItem, {
                    title: weighingBridge.name,
                    key: weighingBridge.name
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_WeighingBridgeData, {
                            data: _ctx.weighingBridgeData(weighingBridge)
                        }, null, 8, ["data"]),
                        _cache[0] || (_cache[0] = _createElementVNode("hr", null, null, -1)),
                        _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("shared.components.opening-hours-widget.title")), 1),
                        _createVNode(_component_OpeningHours, {
                            openingHours: weighingBridge.openingHours
                        }, null, 8, ["openingHours"])
                    ]),
                    _: 2
                }, 1032, ["title"]));
            }), 128))
        ]),
        _: 1
    }, 8, ["title", "hasData", "noDataMsg", "name"]));
}
