import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = { class: "display-attachments" };
const _hoisted_2 = { class: "attachments-title" };
const _hoisted_3 = { class: "d-flex gap-2" };
const _hoisted_4 = { class: "column-description" };
const _hoisted_5 = { class: "column-header" };
const _hoisted_6 = { class: "d-flex gap-2" };
const _hoisted_7 = { class: "column-description" };
const _hoisted_8 = { class: "column-header" };
const _hoisted_9 = { class: "attachment-title" };
const _hoisted_10 = { class: "column-description" };
const _hoisted_11 = { class: "column-description description" };
const _hoisted_12 = ["href"];
const _hoisted_13 = {
    key: 2,
    class: "column-description mb-3"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_LocalisedFieldTag = _resolveComponent("LocalisedFieldTag");
    const _component_Icon = _resolveComponent("Icon");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
        (_ctx.isDriverBriefing && _ctx.attachments?.length)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.attachments, (attachment, idx) => {
                return (_openBlock(), _createElementBlock("div", {
                    key: idx,
                    class: "attachment-element"
                }, [
                    _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("shared.components.attachment-widget.title")) + ":", 1),
                        _createElementVNode("div", _hoisted_5, [
                            _createElementVNode("div", null, _toDisplayString(_ctx.getLocalisedAttachment(attachment.values)?.title), 1),
                            (_ctx.isFallbackAttachment(attachment.values))
                                ? (_openBlock(), _createBlock(_component_LocalisedFieldTag, { key: 0 }))
                                : _createCommentVNode("", true)
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("shared.components.attachment-widget.description")) + ":", 1),
                        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.getLocalisedAttachment(attachment.values)?.description), 1)
                    ])
                ]));
            }), 128))
            : (_ctx.attachments?.length)
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.attachments, (attachment, idx) => {
                    return (_openBlock(), _createElementBlock("div", {
                        key: idx,
                        class: "attachment-element"
                    }, [
                        _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.getLocalisedAttachment(attachment.values)?.title), 1),
                            (_ctx.isFallbackAttachment(attachment.values))
                                ? (_openBlock(), _createBlock(_component_LocalisedFieldTag, { key: 0 }))
                                : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.getLocalisedAttachment(attachment.values)?.description), 1),
                        (_ctx.getLocalisedAttachment(attachment.values)?.url)
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                href: _ctx.getLocalisedAttachment(attachment.values)?.url,
                                target: "_blank",
                                class: "url"
                            }, [
                                _createTextVNode(_toDisplayString(_ctx.$t("shared.components.attachment-widget.more-information")) + " ", 1),
                                _createVNode(_component_Icon, { name: "launch" })
                            ], 8, _hoisted_12))
                            : _createCommentVNode("", true)
                    ]));
                }), 128))
                : (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.emptyStateMsg), 1))
    ]));
}
