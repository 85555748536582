import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "column-header" };
const _hoisted_2 = { class: "column-description" };
const _hoisted_3 = { class: "column-header" };
const _hoisted_4 = { class: "column-description" };
const _hoisted_5 = { class: "column-header" };
const _hoisted_6 = { class: "column-description" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_SubmoduleField = _resolveComponent("SubmoduleField");
    const _component_SubModule = _resolveComponent("SubModule");
    return (_openBlock(), _createBlock(_component_SubModule, {
        title: _ctx.$t('sub-modules.weighing.name'),
        icon: "balance",
        hasData: _ctx.hasData,
        noDataMsg: _ctx.$t('loady2go.no-data'),
        name: _ctx.WeighingSlug
    }, {
        default: _withCtx(() => [
            _createVNode(_component_SubmoduleField, {
                hasData: _ctx.modelValue.truckWeighing !== null
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("sub-modules.weighing.truck-weighing")), 1),
                    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.getTranslation(_ctx.modelValue.truckWeighing, "required", "not-required")), 1)
                ]),
                _: 1
            }, 8, ["hasData"]),
            _createVNode(_component_SubmoduleField, {
                hasData: !!_ctx.modelValue.weighingToBeExecuted.length
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("sub-modules.weighing.weighing-to-be-executed")), 1),
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getValues(_ctx.modelValue.weighingToBeExecuted, "WeighingToBeExecuted")), 1)
                ]),
                _: 1
            }, 8, ["hasData"]),
            _createVNode(_component_SubmoduleField, {
                hasData: _ctx.modelValue.weighingBridgeOnSite !== null
            }, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("sub-modules.weighing.weighing-bridge-on-site")), 1),
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.getTranslation(_ctx.modelValue.weighingBridgeOnSite, "yes", "no")), 1)
                ]),
                _: 1
            }, 8, ["hasData"])
        ]),
        _: 1
    }, 8, ["title", "hasData", "noDataMsg", "name"]));
}
