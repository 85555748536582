import { defineComponent as _defineComponent } from 'vue';
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed } from "vue";
import icons from "@/icons/icons";
export default /*@__PURE__*/ _defineComponent({
    __name: 'svg-icon',
    props: {
        name: { type: String, required: true },
    },
    setup(__props) {
        const props = __props;
        const icon = computed(() => icons[props.name]);
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(_resolveDynamicComponent(icon.value), { class: "icon" }));
        };
    }
});
